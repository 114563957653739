import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import useOverflowTracker from '@aurora/shared-client/components/useOverflowTracker';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import layout from './OverflowSet.module.pcss';

export interface OverflowSetItem {
  /**
   * Render item's key
   */
  key: string;
  /**
   * Render the React element
   */
  onRender: React.ReactNode;
}

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Heading for the overfow set.
   */
  overflowSetHeading?: string;
  /**
   * Text added to the show more filter button.
   */
  moreFilterButtonText?: string;
  /**
   * An array containing filter items to render
   */
  items: OverflowSetItem[];
  /**
   * An array containing overflowed filter items to render
   */
  overflowedItems?: OverflowSetItem[];
  /**
   * Whether to always wrap the overflowed items.
   * This will overwrite the collapse/expand behaviour.
   */
  alwaysWrapOverflowed?: boolean;
}

/**
 * Display the filter section
 *
 * @author Amit Agrawal
 */
const OverflowSet = ({
  className,
  overflowSetHeading,
  moreFilterButtonText,
  items,
  overflowedItems = [],
  alwaysWrapOverflowed = false
}: Props): React.ReactElement => {
  const cx = useClassNameMapper(layout);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.OVERFLOW_SET);
  const overflowElementRef = useRef<HTMLDivElement>();
  const { visibleCount, ref } = useOverflowTracker(items, overflowElementRef);
  const moreFiltersItems = items.slice(visibleCount);

  const [showMoreFilterSection, setShowMoreFilterSection] = useState<boolean>(false);

  if (textLoading) {
    return null;
  }

  /**
   * Renders filter items
   * @param filterItems list of filter items
   * @param hideOverflowed flag to hide overflowed items
   */
  const renderFilterItems = (
    filterItems: OverflowSetItem[],
    hideOverflowed: boolean
  ): React.ReactElement[] => {
    return filterItems.map((item, index) => (
      <div
        className={cx({ 'lia-is-hidden': hideOverflowed && index >= visibleCount })}
        key={item.key}
      >
        {item.onRender}
      </div>
    ));
  };

  /**
   * Renders inline filter section
   */
  const renderInlineFilters = (): React.ReactElement => {
    return (
      <div className={cx('lia-filter-items')} ref={ref}>
        <div className={cx('lia-items')} ref={overflowElementRef}>
          {renderFilterItems(items, true)}
        </div>
        <div className={cx('lia-more')}>
          {(moreFiltersItems.length > 0 || overflowedItems.length > 0) && (
            <Button
              className={cx('lia-g-loader-btn')}
              variant={ButtonVariant.LINK}
              onClick={() => setShowMoreFilterSection(true)}
              aria-expanded="false"
            >
              {moreFilterButtonText || formatMessage('more')}
              <Icon
                color={IconColor.LOAD_TEXT}
                size={IconSize.PX_16}
                icon={Icons.ChevronDownIcon}
                className={cx('lia-g-ml-5')}
              />
            </Button>
          )}
        </div>
      </div>
    );
  };

  /**
   * Renders expanded filter section
   */
  function renderExpandedFilters() {
    // TODO: `items` is all items and `overflowedItems` are the ones that are overflowing.
    // This seems like it's going to add dupes.
    const filterItems = [...items, ...overflowedItems];
    return (
      <>
        {showMoreFilterSection && (
          <div className={cx('lia-filter-items-header')}>
            <h5 className={cx('lia-filter-heading')}>
              {overflowSetHeading || formatMessage('allFilters')}
            </h5>
            <Button
              className={cx('lia-filter-close-button')}
              variant={ButtonVariant.NO_VARIANT}
              onClick={() => setShowMoreFilterSection(false)}
              aria-label={formatMessage('ariaLabel.collapseMoreFilters')}
            >
              <Icon
                icon={Icons.CloseIcon}
                size={IconSize.PX_14}
                className={cx('lia-filter-close-button-icon')}
              />
            </Button>
          </div>
        )}
        <div className={cx('lia-filter-expanded-items')}>
          {filterItems && renderFilterItems(filterItems, false)}
        </div>
      </>
    );
  }

  return (
    <div className={cx(className, 'lia-filter-container')} data-testid="OverflowSet">
      {showMoreFilterSection || alwaysWrapOverflowed
        ? renderExpandedFilters()
        : renderInlineFilters()}
    </div>
  );
};

export default OverflowSet;
