import type { ApolloQueryResult } from '@apollo/client';
import type {
  CanRemoveTagListQuery,
  CanRemoveTagListQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import Icons from '../../../icons';
import { LoadingSpacing } from '../../../types/enums';
import Button from '../../common/Button/Button';
import { ButtonVariant } from '../../common/Button/enums';
import { IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import { PagerVariant } from '../../common/Pager/enums';
import Pager from '../../common/Pager/Pager';
import AppContext from '../../context/AppContext/AppContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import useTranslation from '../../useTranslation';
import canRemoveTagListQuery from './CanRemoveTagList.query.graphql';
import localStyle from './TagEditor.module.pcss';
import type TagOption from './TagOption';

const log = getLog(module);
const TagRemoveButton = dynamic(() => import('./TagRemoveButton'));

interface Props {
  /**
   * Class name(s) to apply
   */
  className?: string;
  /**
   * Whether the user can add/remove tags
   */
  canTag?: boolean;

  /**
   * The current tags, if any.
   */
  tags: TagOption[];

  /**
   * @callback
   * A callback function called when a tag is removed.
   */
  onTagRemove: (tag: TagOption) => Promise<unknown> | void;

  /**
   * Is there another page of tags?
   */
  hasNextPage?: boolean;

  /**
   * @callback
   * A callback to load a page of tags.
   */
  onPageLoad?: () => Promise<ApolloQueryResult<unknown> | void>;

  /**
   * Click handler when a tag is clicked.
   */
  onTagClick?: (tag: TagOption) => void;
  /**
   * Whether the tags are clickable.
   */
  isPreview?: boolean;
  /**
   * Whether to show remove tag button
   */
  canRemoveTag?: boolean;
  /**
   * whether field is present in admin app or enduser app
   */
  isAdminSetting?: boolean;
  /**
   * Whether to show remove tag dialog
   */
  showRemoveTagDialog?: boolean;
}

/**
 * Display a list message tags.
 *
 * Enables removing of tags from a message.
 *
 * @constructor
 *
 * @author Dolan Halbrook
 */
const TagList: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  canRemoveTag = true,
  showRemoveTagDialog = false,
  onTagRemove,
  tags,
  onTagClick,
  hasNextPage = false,
  isPreview = true,
  onPageLoad = (): Promise<ApolloQueryResult<unknown> | void> => Promise.resolve(),
  canTag = true,
  isAdminSetting
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.TAG_LIST);
  const cx = useClassNameMapper(localStyle);
  async function removeTag(removedTag): Promise<void> {
    await onTagRemove(removedTag);
  }

  const { contextMessage } = useContext(AppContext);
  const {
    error,
    data: canRemoveTagListData,
    loading: canRemoveTagListLoading
  } = useQueryWithTracing<CanRemoveTagListQuery, CanRemoveTagListQueryVariables>(
    module,
    canRemoveTagListQuery,
    {
      variables: {
        messageId: contextMessage && contextMessage.id
      },
      skip: !contextMessage
    }
  );

  if (textLoading || canRemoveTagListLoading) {
    return null;
  } else if (error) {
    log.error('Error fetching data:', error);
  }

  const tagListWithPolicyMapping =
    canRemoveTagListData &&
    canRemoveTagListData.canRemoveTags.result?.reduce(
      (localTags, removeTagPolicy) => ({
        ...localTags,
        [removeTagPolicy.tagText]: removeTagPolicy.canRemoveTag
      }),
      {}
    );

  function canRemoveTagOption(tag: TagOption): boolean {
    if (!contextMessage) {
      return true;
    } else {
      return (
        tagListWithPolicyMapping &&
        (tagListWithPolicyMapping[tag.label] || tagListWithPolicyMapping[tag.label] === undefined)
      );
    }
  }

  /**
   * Function to render the tag button
   * @param tag the tag information
   */
  function renderTag(tag: TagOption): React.ReactElement {
    return (
      <Button
        variant={ButtonVariant.UNSTYLED}
        className={cx('lia-tag-text lia-tag-button', { 'lia-tag-preview': !isPreview })}
        data-testid="tag"
        onClick={() => onTagClick(tag)}
        title={formatMessage('tag', { label: tag.label }).toUpperCase()}
      >
        {formatMessage('tag', { label: tag.label })}
      </Button>
    );
  }

  return (
    <>
      {tags?.map((tag, i) => (
        <Badge
          key={tag.label + '-' + i}
          variant="light"
          className={cx(className, 'lia-tag', { 'lia-tag-preview': !isPreview })}
          data-testid={`TagList.${tag.label}`}
        >
          {onTagClick ? (
            renderTag(tag)
          ) : (
            <span
              className={cx('lia-tag-text')}
              title={formatMessage('tag', { label: tag.label }).toUpperCase()}
            >
              {formatMessage('tag', { label: tag.label })}
            </span>
          )}
          {canTag && isPreview && canRemoveTagOption(tag) && (
            <TagRemoveButton
              tag={tag}
              showRemoveTagDialog={showRemoveTagDialog}
              onRemove={removeTag}
              isDisabled={!canRemoveTag}
              isAdminSetting={isAdminSetting}
            />
          )}
        </Badge>
      ))}
      {hasNextPage && (
        <Pager
          variant={{
            type: PagerVariant.LOAD_MORE,
            props: {
              loadingSpacing: LoadingSpacing.NONE,
              clearLoadingAfterUpdate: true,
              useCaret: false
            }
          }}
          loadPage={onPageLoad}
          pageInfo={{ hasNextPage }}
          className={cx('badge badge-light lia-tag lia-tag-pager')}
        >
          <Icon
            size={IconSize.PX_14}
            icon={Icons.EllipsisIcon}
            className={cx('lia-tag-pager-icon')}
          />
        </Pager>
      )}
    </>
  );
};
export default TagList;
